import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const router = new VueRouter({
  routes: [
    // 百家乐
    {
      path: "/Bacarat",
      name: "Bacarat",
      component: () => import("@/view/Bacarat.vue"),
    },
    // 龙虎
    {
      path: "/DragonTiger",
      name: "DragonTiger",
      component: () => import("@/view/DragonTiger.vue"),
    },
    // 牛牛
    {
      path: "/Bulls",
      name: "Bulls",
      component: () => import("@/view/Bulls.vue"),
    },
    // 三公
    {
      path: "/SanGong",
      name: "SanGong",
      component: () => import("@/view/SanGong.vue"),
    },
    // 龙凤炸金花
    {
      path: "/GoldenFlower",
      name: "GoldenFlower",
      component: () => import("@/view/GoldenFlower.vue"),
    },
    // 德州扑克
    {
      path: "/TexasHoldem",
      name: "TexasHoldem",
      component: () => import("@/view/TexasHoldem.vue"),
    },
    // 骰宝
    {
      path: "/SicBo",
      name: "SicBo",
      component: () => import("@/view/SicBo.vue"),
    },
    // 色碟
    {
      path: "/SeDie",
      name: "SeDie",
      component: () => import("@/view/SeDie.vue"),
    },
    // 安达巴哈
    {
      path: "/AndarBahar",
      name: "AndarBahar",
      component: () => import("@/view/AndarBahar.vue"),
     },
         // 21点
    {
      path: "/BlackJack",
      name: "BlackJack",
      component: () => import("@/view/BlackJack.vue"),
     },
    // 轮盘
    {
      path: "/Roulette",
      name: "Roulette",
      component: () => import("@/view/Roulette.vue"),
    },
    // 番摊
    {
      path: "/Fantan",
      name: "Fantan",
      component: () => import("@/view/Fantan.vue"),
    },
    // 炸金花
    {
      path: "/FriedGoldenFlower",
      name: "FriedGoldenFlower",
      component: () => import("@/view/FriedGoldenFlower.vue"),
    },
  ],
});
export default router;
