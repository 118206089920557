import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import i18n from './lang'
import 'vant/lib/index.css' // 引入Vant全局样式
import { Lazyload } from "vant";
Vue.use(Lazyload);
// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  lazyComponent: true,
});


Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')


