<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      gameTypeHelp: "",
    };
  },
  created() {
    this.initRouter();
  },
  methods: {
    initRouter() {
      const router = this.$router;
      window.onmessage = (e) => {
        if (e.data.type == "fromCocos") {
          localStorage.setItem("lang_help", e.data.data.gameType_lang);
          this.gameType_help = e.data.data.gameType_help;
          const routerUrl = {
            bjl: "/Bacarat", // 百家乐
            longhu: "/DragonTiger", // 龙虎
            niuniu: "/Bulls", // 牛牛
            sangong: "/SanGong", // 三公
            goldenFlower: "/GoldenFlower", // 龙凤炸金花
            texasHoldem: "/TexasHoldem", // 德州扑克
            sicBo: "/SicBo", // 骰宝
            seDie: "/SeDie", // 色碟
            roulette: "/Roulette", // 轮盘
            fantan: "/Fantan", // 番摊
            friedGoldenFlower: "/FriedGoldenFlower", // 炸金花
            AndarBahar:"/AndarBahar",//安达巴哈
            Blackjack:"/BlackJack",//21点
          };
          if (this.gameType_help) {
            router.push(routerUrl[this.gameType_help]);
          }
        }
      };
    },
  },
};
</script>

<style>
body {
  -webkit-font-smoothing: antialiased;
}

body,
button,
dd,
div,
dl,
figure,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
ol,
p,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

em {
  font-style: italic;
}

#app {
  font-family: Microsoft YaHei, -apple-system, BlinkMacSystemFont, Segoe UI,
    PingFang SC, Hiragino Sans GB, Helvetica Neue, Helvetica, Arial, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  color: #666;
}
</style>
