import Vue from 'vue'
import zh from './zh';
import en from './en2';

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: (function () {
        if (localStorage.getItem('lang_help')) {
            return localStorage.getItem('lang_help')
        }
        return 'zh'
    }()),
    messages: {
        zh,
        en
    },
    silentTranslationWarn: true
})

export default i18n